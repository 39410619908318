import React, { Suspense, lazy, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider, AuthContext } from './components/auth/AuthContext';
import ProtectedRoute from './components/auth/ProtectedRoute';

const EventMap = lazy(() => import('./components/_map/EventMap'));
const AdminPanel = lazy(() => import('./components/admin/AdminPanel'));
const Login = lazy(() => import('./components/auth/AdminLoginForm'));

// New Component to handle routes
const RoutesComponent = () => {
    const { loading } = useContext(AuthContext); // Use AuthContext inside a component

    if (loading) {
        return <div>Loading...</div>; // Loading state
    }

    return (
        <Routes>
            <Route exact path="/" element={<EventMap/>} />
            <Route path="/login" element={<Login/>} />
            <Route element={<ProtectedRoute />}>
                <Route path="/admin" element={<AdminPanel />} />
            </Route>
        </Routes>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <RoutesComponent />
                </Suspense>
            </Router>
        </AuthProvider>
    );
}

export default App;


// import React from 'react';
// import { EventMap } from './components/EventMap'; // Adjust the path based on your file structure
// // import { Demo, EventMap} from './components/testMap'; // Adjust the path based on your file structure
// import './App.css';

// function App() {
//   return (
//     <div>
//       {/* <Demo /> */}
//       <EventMap/>
//     </div>
//   );
// }

// export default App;
