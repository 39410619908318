// src/components/ProtectedRoute.js

import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './useAuth';

const ProtectedRoute = () => {
    const { user, loading } = useAuth();

    if (loading) {
        return null;
    }

    if (!user) {
        // Redirect to login if the user is not authenticated
        // console.log("Redirecting to login...")
        return <Navigate to="/login" />;
    }

    return <Outlet/>;
};

export default ProtectedRoute;
